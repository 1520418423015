import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cards from "../components/cards/cards"
import Video from "../components/video/video"
import bgVideo from "../components/video/02_THS_Animatic_Final_Live-IPTV_Compressed.mp4"
import videoPoster from "../components/video/video-poster-iptv-icc.jpg"

const LiveIPTVICC = () => (
  <Layout>
    <SEO title="IPTV ICC 2019" />
    <div className="cardsContainer cardsContainer--live-iptv cardsContainer--icc">
      <Cards postCount={5} hero={true} />
      <Video src={bgVideo} poster={videoPoster} />
    </div>
  </Layout>
)

export default LiveIPTVICC
